////////////////////////
// Fonts Partials
//
// All of your fonts definition should go in this file.
////////////////////////

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-thin.eot');
  src: url('../../fonts/k2d-thin.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-thin.woff2') format('woff2'),
       url('../../fonts/k2d-thin.woff') format('woff'),
       url('../../fonts/k2d-thin.ttf') format('truetype'),
       url('../../fonts/k2d-thin.svg#k2d_thinregular') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-thinitalic.eot');
  src: url('../../fonts/k2d-thinitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-thinitalic.woff2') format('woff2'),
       url('../../fonts/k2d-thinitalic.woff') format('woff'),
       url('../../fonts/k2d-thinitalic.ttf') format('truetype'),
       url('../../fonts/k2d-thinitalic.svg#k2d_thinitalic') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-extralight.eot');
  src: url('../../fonts/k2d-extralight.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-extralight.woff2') format('woff2'),
       url('../../fonts/k2d-extralight.woff') format('woff'),
       url('../../fonts/k2d-extralight.ttf') format('truetype'),
       url('../../fonts/k2d-extralight.svg#k2d_extralightregular') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-extralightitalic.eot');
  src: url('../../fonts/k2d-extralightitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-extralightitalic.woff2') format('woff2'),
       url('../../fonts/k2d-extralightitalic.woff') format('woff'),
       url('../../fonts/k2d-extralightitalic.ttf') format('truetype'),
       url('../../fonts/k2d-extralightitalic.svg#k2d_extralightitalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-light.eot');
  src: url('../../fonts/k2d-light.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-light.woff2') format('woff2'),
       url('../../fonts/k2d-light.woff') format('woff'),
       url('../../fonts/k2d-light.ttf') format('truetype'),
       url('../../fonts/k2d-light.svg#k2d_lightregular') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-lightitalic.eot');
  src: url('../../fonts/k2d-lightitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-lightitalic.woff2') format('woff2'),
       url('../../fonts/k2d-lightitalic.woff') format('woff'),
       url('../../fonts/k2d-lightitalic.ttf') format('truetype'),
       url('../../fonts/k2d-lightitalic.svg#k2d_lightitalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-regular.eot');
  src: url('../../fonts/k2d-regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-regular.woff2') format('woff2'),
       url('../../fonts/k2d-regular.woff') format('woff'),
       url('../../fonts/k2d-regular.ttf') format('truetype'),
       url('../../fonts/k2d-regular.svg#k2dregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-italic.eot');
  src: url('../../fonts/k2d-italic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-italic.woff2') format('woff2'),
       url('../../fonts/k2d-italic.woff') format('woff'),
       url('../../fonts/k2d-italic.ttf') format('truetype'),
       url('../../fonts/k2d-italic.svg#k2ditalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-medium.eot');
  src: url('../../fonts/k2d-medium.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-medium.woff2') format('woff2'),
       url('../../fonts/k2d-medium.woff') format('woff'),
       url('../../fonts/k2d-medium.ttf') format('truetype'),
       url('../../fonts/k2d-medium.svg#k2d_mediumregular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-mediumitalic.eot');
  src: url('../../fonts/k2d-mediumitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-mediumitalic.woff2') format('woff2'),
       url('../../fonts/k2d-mediumitalic.woff') format('woff'),
       url('../../fonts/k2d-mediumitalic.ttf') format('truetype'),
       url('../../fonts/k2d-mediumitalic.svg#k2d_mediumitalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-semibold.eot');
  src: url('../../fonts/k2d-semibold.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-semibold.woff2') format('woff2'),
       url('../../fonts/k2d-semibold.woff') format('woff'),
       url('../../fonts/k2d-semibold.ttf') format('truetype'),
       url('../../fonts/k2d-semibold.svg#k2d_semiboldregular') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-semibolditalic.eot');
  src: url('../../fonts/k2d-semibolditalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-semibolditalic.woff2') format('woff2'),
       url('../../fonts/k2d-semibolditalic.woff') format('woff'),
       url('../../fonts/k2d-semibolditalic.ttf') format('truetype'),
       url('../../fonts/k2d-semibolditalic.svg#k2d_semibolditalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-bold.eot');
  src: url('../../fonts/k2d-bold.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-bold.woff2') format('woff2'),
       url('../../fonts/k2d-bold.woff') format('woff'),
       url('../../fonts/k2d-bold.ttf') format('truetype'),
       url('../../fonts/k2d-bold.svg#k2dbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-bolditalic.eot');
  src: url('../../fonts/k2d-bolditalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-bolditalic.woff2') format('woff2'),
       url('../../fonts/k2d-bolditalic.woff') format('woff'),
       url('../../fonts/k2d-bolditalic.ttf') format('truetype'),
       url('../../fonts/k2d-bolditalic.svg#k2dbold_italic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-extrabold.eot');
  src: url('../../fonts/k2d-extrabold.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-extrabold.woff2') format('woff2'),
       url('../../fonts/k2d-extrabold.woff') format('woff'),
       url('../../fonts/k2d-extrabold.ttf') format('truetype'),
       url('../../fonts/k2d-extrabold.svg#k2d_extraboldregular') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'K2D';
  src: url('../../fonts/k2d-extrabolditalic.eot');
  src: url('../../fonts/k2d-extrabolditalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/k2d-extrabolditalic.woff2') format('woff2'),
       url('../../fonts/k2d-extrabolditalic.woff') format('woff'),
       url('../../fonts/k2d-extrabolditalic.ttf') format('truetype'),
       url('../../fonts/k2d-extrabolditalic.svg#k2d_extrabolditalic') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-light.eot');
  src: url('../../fonts/rubik-light.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-light.woff2') format('woff2'),
       url('../../fonts/rubik-light.woff') format('woff'),
       url('../../fonts/rubik-light.ttf') format('truetype'),
       url('../../fonts/rubik-light.svg#rubiklight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-lightitalic.eot');
  src: url('../../fonts/rubik-lightitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-lightitalic.woff2') format('woff2'),
       url('../../fonts/rubik-lightitalic.woff') format('woff'),
       url('../../fonts/rubik-lightitalic.ttf') format('truetype'),
       url('../../fonts/rubik-lightitalic.svg#rubiklight_italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-regular.eot');
  src: url('../../fonts/rubik-regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-regular.woff2') format('woff2'),
       url('../../fonts/rubik-regular.woff') format('woff'),
       url('../../fonts/rubik-regular.ttf') format('truetype'),
       url('../../fonts/rubik-regular.svg#rubikregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-italic.eot');
  src: url('../../fonts/rubik-italic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-italic.woff2') format('woff2'),
       url('../../fonts/rubik-italic.woff') format('woff'),
       url('../../fonts/rubik-italic.ttf') format('truetype'),
       url('../../fonts/rubik-italic.svg#rubikitalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-medium.eot');
  src: url('../../fonts/rubik-medium.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-medium.woff2') format('woff2'),
       url('../../fonts/rubik-medium.woff') format('woff'),
       url('../../fonts/rubik-medium.ttf') format('truetype'),
       url('../../fonts/rubik-medium.svg#rubikmedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-mediumitalic.eot');
  src: url('../../fonts/rubik-mediumitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-mediumitalic.woff2') format('woff2'),
       url('../../fonts/rubik-mediumitalic.woff') format('woff'),
       url('../../fonts/rubik-mediumitalic.ttf') format('truetype'),
       url('../../fonts/rubik-mediumitalic.svg#rubikmedium_italic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-bold.eot');
  src: url('../../fonts/rubik-bold.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-bold.woff2') format('woff2'),
       url('../../fonts/rubik-bold.woff') format('woff'),
       url('../../fonts/rubik-bold.ttf') format('truetype'),
       url('../../fonts/rubik-bold.svg#rubikbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-bolditalic.eot');
  src: url('../../fonts/rubik-bolditalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-bolditalic.woff2') format('woff2'),
       url('../../fonts/rubik-bolditalic.woff') format('woff'),
       url('../../fonts/rubik-bolditalic.ttf') format('truetype'),
       url('../../fonts/rubik-bolditalic.svg#rubikbold_italic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-black.eot');
  src: url('../../fonts/rubik-black.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-black.woff2') format('woff2'),
       url('../../fonts/rubik-black.woff') format('woff'),
       url('../../fonts/rubik-black.ttf') format('truetype'),
       url('../../fonts/rubik-black.svg#rubikblack') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik-blackitalic.eot');
  src: url('../../fonts/rubik-blackitalic.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/rubik-blackitalic.woff2') format('woff2'),
       url('../../fonts/rubik-blackitalic.woff') format('woff'),
       url('../../fonts/rubik-blackitalic.ttf') format('truetype'),
       url('../../fonts/rubik-blackitalic.svg#rubikblack_italic') format('svg');
  font-weight: 900;
  font-style: italic;
}
