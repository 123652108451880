////////////////////////
// Print File
//
// This file gets turned into print.css.
// This file is styles specific to Printed files.
////////////////////////

////////////////////////
// Base Import
////////////////////////
@import 'bordas/global/base';


////////////////////////
// Custom Code
////////////////////////
#block-bordas-block-bordas-refonte-footer-menu,
#block-bordas-block-bordas-refonte-mes-favoris,
#block-bordas-block-bordas-refonte-mon-compte,
#block-bordas-block-bordas-refonte-mon-panier,
#block-bordas-search-search-form,
#main-content > .tabs,
#pre_footer,
.header-bottom,
.menu-mobile,
.social-sharing-items {
  display: none;
}

#sliding-popup {
  display: none !important;
}

@page {
  size: A4;
  padding: 0;
  border: none;
  border-collapse: collapse;
  margin: 18mm 15.4mm;
}
